/* App-wide styles */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  object-fit: contain;
  font-family: 'Segoe UI', sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
}

:root {
  --vh: 1vh;
}

#root {
  display: flex;
  object-fit: contain;
}

.mainWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  flex-grow: 1;
  width: 100%;
}

header,
footer {
  position: fixed;
  width: 100%;
}

header {
  top: 0;
}

footer {
  bottom: 0;
}

.mainContent {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.homePage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.main-content {
  max-height: 100%;
  background-image: repeating-linear-gradient(
    0deg,
    #d6f5ff 0px,
    #d6f5ff 4px,
    #c8df8e 4px,
    #c8df8e 8px
  );
  margin-top: -1px;
  width: 100%;
  box-shadow: inset 0 15px 10px -10px black;
}

.internal-content {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
  margin: 10px 20px;
}

.page-title {
  width: 100%;
  height: 50px;
  background-color: #006837;
  border-radius: 30px;
  color: #fff;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.page-title h1 {
  font-size: 26px;
  font-weight: 500;
  line-height: 50px;
}

.page-title svg {
  width: 30px;
  height: 28px;
  fill: #fff;
}

.homePage {
  overflow: hidden;
}

#main-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-circle {
  width: calc(2 * 1.5 * 125px * 0.75);
  height: calc(2 * 1.5 * 125px * 0.75);
  background-color: rgba(139, 196, 62, 0.3);
  border-radius: 50%;
  position: absolute;
}

.background-circle2 {
  width: calc(2 * 1.5 * 135px * 0.75);
  height: calc(2 * 1.5 * 135px * 0.75);
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  outline: 4px solid white;
}

#circle-container {
  width: 120px;
  height: 120px;
  background-color: #006837;
  border-radius: 50%;
  border: 2px solid white;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#circle-container::after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 2px solid white;
}

#circle-container img {
  max-width: calc(90% - 15px);
  max-height: calc(90% - 15px);
  object-fit: contain;
}

.white-circle {
  width: 105px;
  height: 105px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  border: 5px solid #8bc43e;
  box-shadow: inset 0 8px 16px rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.homePage .icon-svg {
  width: 67%;
  height: 67%;
  fill: #e7e7e7;
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
}

.rotated-icon svg {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.circle-text {
  position: absolute;
  text-align: center;
  white-space: nowrap;
  font-size: 26px;
  color: #006837;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: auto;
  font-weight: bolder;
}

.exploring-header {
  position: absolute;
  top: 16%;
  width: 100%;
  text-align: center;
  z-index: 2;
  font-size: 34px;
  color: #006837;
}

.content-item:nth-child(odd) {
  background-color: #04a2c922;
}

.content-item {
  text-align: center;
  padding: 5px;
  margin-top: 5px;
  overflow: hidden;

  h2 {
    color: #007037;
    margin: 0;
  }

  .descriptBox {
    display: flex;
    border-bottom: 1px solid #aaaaaa;
    padding: 10px;

    p {
      font-size: 1.2rem;
      margin: 0;
    }
  }

  .descriptBox img {
    width: 139px;
    height: 106px;
  }

  .reviews-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
    width: 100%;

    .stars {
      font-size: 2.2rem;
      color: #ffd700;
      display: flex;
    }

    .star {
      display: inline-block;
    }

    .star.half {
      position: relative;
    }

    .star.half::before {
      content: '★';
      position: absolute;
      left: 0;
      width: 50%;
      overflow: hidden;
      color: #ffd700;
    }

    .star.empty {
      color: #ddd;
    }

    .reviews-text {
      font-size: 1rem;
      color: #333;
      margin-top: 5px;
      text-align: center;
    }

    .more-button {
      background-color: #bcd743;
      color: #005a29;
      border: none;
      padding: 10px 25px;
      cursor: pointer;
      font-size: 1.1rem;
      font-weight: bold;
      border-radius: 10px;
      margin-left: 10px;
    }

    .more-button:hover {
      background-color: #005a29;
    }
  }
}

.hide {
  display: none;
}

.internal-content {
  position: relative;
}

.page-title-overlay {
  position: relative;
  z-index: 10;
}

main {
  width: 100%;
}

.app-container {
  display: flex;
  height: 100vh;
  position: relative;

  &.landscape {
    header,
    footer {
      width: 50%;
      flex-direction: column;
    }

    main {
      position: absolute;
      top: 0;
      right: 0;
      width: 48%;
      height: 100%;
      flex-grow: 0;
      padding: 0 !important;
      z-index: 101;
    }

    footer .circle-background {
      left: 45%;
    }

    #main-container {
      margin-bottom: -30px;
    }

    .exploring-header {
      top: -10px;
    }

    .main-content {
      box-shadow: none;
    }

    .square-button-link {
      order: 3;
    }

    .centerWrap {
      order: 1;
      padding-top: 60px;
      width: 60%;
    }
    .centered-image {
      max-height: 150px;
    }

    .search-container {
      margin-top: -30px;
    }

    .footer-circles {
      width: 90%;
    }

    .sort-menu {
      width: 25%;
      border-bottom-right-radius: 12px;
    }

    .sort-menu {
      z-index: 10005;
    }
  }

  &.desktop {
    header,
    footer {
      width: 50%;
      flex-direction: column;
    }

    main {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      flex-grow: 0;
      padding: 0 !important;
      z-index: 101;
    }

    footer .circle-background {
      left: 45%;
      width: 100%
    }

    #main-container {
      margin-bottom: -30px;
    }

    .exploring-header {
      top: 100px;
      font-size: 46px;
    }

    .main-content {
      box-shadow: none;
    }

    .square-button-link {
      order: 3;
    }

    .centerWrap {
      order: 1;
      padding-top: 150px;
      width: 60%;
    }

    .centered-image {
      max-height: 350px;
    }

    .background-circle {
      width: calc(2 * 1.5 * 125px * 0.75 * 1.4);
      height: calc(2 * 1.5 * 125px * 0.75 * 1.4);
      background-color: rgba(
        139,
        196,
        62,
        0.3
      );
      border-radius: 50%;
      position: absolute;
    }

    .background-circle2 {
      width: calc(2 * 1.5 * 135px * 0.75 * 1.4);
      height: calc(2 * 1.5 * 135px * 0.75 * 1.4);
      background-color: transparent;
      border-radius: 50%;
      position: absolute;
      outline: 4px solid white;
    }

    #circle-container {
      width: calc(120px * 1.4);
      height: calc(120px * 1.4);
      padding: calc(10px * 1.4);
      position: relative;
    }

    #circle-container::after {
      top: calc(5px * 1.4);
      left: calc(5px * 1.4);
      right: calc(5px * 1.4);
      bottom: calc(5px * 1.4);
    }

    .centered-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: calc(65% * 1.4);
      max-height: calc(65% * 1.4);
      z-index: 1;
    }

    .white-circle {
      width: calc(105px * 1.4);
      height: calc(105px * 1.4);
      border: calc(5px * 1.4) solid #8bc43e;
      box-shadow: inset 0 calc(8px * 1.4) calc(16px * 1.4) rgba(0, 0, 0, 0.7);
    }

    .homePage .icon-svg {
      width: calc(67% * 1.2);
      height: calc(67% * 1.2);
    }

    .circle-text {
      font-size: calc(26px * 1.4);
    }

    .sliding-menu {
      top: 0;
      left: 0;
      height: -moz-fit-content;
      height: fit-content;
      width: 48%;
      transform: translateX(-110%);
      z-index: 1000;
      border-bottom-right-radius: 12px;
    }
    .sort-menu {
      width: 15%;
      z-index: 1001;
      border-bottom-right-radius: 12px;
    }

    .desktop-circle {
      margin-top: -20px;
    }

    .circle-background {
      top: -250px;
    }

    .circle-background::before {
      width: 840px;
      height: 328px;
    }

    .circle-background::after {
      width: 840px;
      height: 328px;
    }

    .circle-background h3 {
      font-size: 79px;
    }

    .circle-background p {
      font-size: 36px;
    }
  }
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #005a29;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.content-box {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 5px 0;
}

.content-image {
  width: 139px;
  height: 106px;
  object-fit: cover;
  margin-right: 10px;
}

.text-box {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.text-box p {
  padding-bottom: 5px;
}

.descriptBox {
  margin-bottom: 10px;
}

.reviews-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reviews-block {
  display: flex;
  align-items: center;
  width: 75%;
  justify-content: space-around;
}

.text-box p {
  margin: 5px 0;
}

.marker-icon {
  transform: translate(-50%, -50%);
  position: absolute;
}

.marker.eat {
  background-color: red;
}

.marker.play {
  background-color: blue;
}

.marker.stay {
  background-color: green;
}

.marker.shop {
  background-color: yellow;
}

.marker.events {
  background-color: purple;
}

.popCard {
  max-width: 300px !important;
  width: 90% !important;
  border-radius: 10px;

  .popWrap {
    width: 100%;
    background-color: white;

    .popTop {
      display: flex;
      justify-content: space-around;
      align-items: center;

      img {
        max-width: 45%;
      }

      h2 {
        color: #bf772f;
        font-size: 1.4rem;
        text-align: center;
      }
    }

    .addyText {
      width: 100%;
      padding: 5px 0;
      border-bottom: 1px solid #aaaaaa;

      p {
        width: 100%;
        text-align: center;
        font-size: 1.1rem;
        line-height: 0.3;
      }
    }

    .popButtonsWrap {
      width: 100%;
      display: flex;
      justify-content: space-around;

      a {
        text-decoration: none;
        color: black;
      }

      .popButtonDevide {
        width: 20%;
        text-align: center;
        cursor: pointer;

        .popButton {
          width: 100%;
          aspect-ratio: 1 / 1;
          background-color: #8bc43e;
          border-radius: 90%;
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 60%;
            fill: white;
          }
        }
      }
    }
  }
}

.mapboxgl-popup-close-button {
  font-size: 16px !important;
}

.box-top {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #aaaaaa;
  border-top: 1px solid #aaaaaa;
}

.share-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  max-width: 400px;
  margin: 20px 0;
}

.share-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #0056b3;
}

.none {
  display: none;
}

.s-menu-item input {
  width: 90%;
  font-size: 1.2rem;
}

.s-menu-item svg {
  fill: rgb(191, 119, 47);
}

.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

.mapboxgl-ctrl-bottom-left {
  display: none !important;
}

@media only screen and (max-width: 700px) {
  .content-item {
    padding-bottom: 0;
  }

  .content-box {
    flex-direction: column;
    margin: 0;
  }

  .box-top {
    flex-direction: column;
  }

  .box-top img {
    width: 100%;
    height: auto;
    max-height: 300px;
  }

  .text-box p {
    max-height: 200px;
  }

  .reviews-container {
    width: 100%;
    padding: 25px 0;
  }

  .content-item:nth-child(odd) .reviews-container {
    background-color: #DEF3F8;
  }
  
  .content-item:nth-child(even) .reviews-container {
    background-color: #FFFFFF;
  }
}