header {
  background-color: #fff;
  text-align: center;
  color: #ffffff;
  height: auto;
  // box-shadow: 0 12px 16px rgba(0, 0, 0, 0.8);
  z-index: 100;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column; /* Changed to column to stack children vertically */
  justify-content: flex-start;
  align-items: center;
  top: 0;
  margin-bottom: 0;
  padding-bottom: 0;

  .header-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding-top: 30px;
    position: relative;
    z-index: 99;
    background-color: #fff;
    box-shadow: 0px 10px -14px 14px #fff;
    margin: 0; /* Ensure no margin */
  }

  .search-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    padding: 5px 0;
    box-sizing: border-box;
    margin: 0;
    z-index: 100;
    background-color: #fff;

    button.sort-button {
      width: 134px;
      padding: 10px;
      background-color: #c8df8e;
      color: #007037;
      border: none;
      border-radius: 5px;
      text-transform: uppercase;
      font-size: 12px;
    }

    .inputBox {
      max-height: 45px;
      display: flex;
      align-items: center;

      input.search-box {
        padding: 10px;
        margin-right: 5px;
        background-color: #e2f8ff;
        border: none;
        border-radius: 5px;
        text-align: center;
      }
    }
  }

  .centerWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    box-sizing: border-box;
    width: 50%;
    padding-bottom: 10px;

    .centered-image {
      width: auto;
      height: auto;
      max-height: 75px;
    }

    .search-box {
      width: auto;
      padding: 8px 8px;
      box-sizing: border-box;
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: center;
      background-color: #e2f8ff;
      border: none;
      text-decoration: none;
    }
  }

  .square-button,
  .circle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    background-color: #bf772f;
    border: none;
  }

  .circle-button {
    background-color: #8bc43e;
  }

  .square-button {
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 12px;
    flex-direction: column;
    padding: 3px;
    width: 62px;
    height: 56px;
    margin-right: 20px;
    color: white;
    fill: white;
    box-shadow: 1px 1px 4px #000000;
    line-height: 1;
    text-decoration: none;
  }

  .square-button span {
    text-decoration: none;
  }

  .square-button-link {
    text-decoration: none;
  }

  .circle-button {
    border-radius: 50%;
    font-size: 26px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    margin-left: 20px;
    color: white;
  }

  .square-button svg {
    width: 25px;
    height: 25px;
  }
}

.centerWrap svg {
  padding: 0 0 0 5px;
}

.header-container,
.search-container {
  box-shadow: 0 1px 0 0 #fff;
}
